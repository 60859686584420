import { authHeader, history } from '../helpers';

const getAllParticipants = async () => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/EventParticipant`,
    requestOptions
  );
  return handleGetParticipantsResponse(response);
};

const getParticipants = async (eventId) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/EventParticipant/${eventId}`,
    requestOptions
  );
  return handleGetParticipantsResponse(response);
};

const deleteParticipant = async (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/EventParticipant/${id}`,
    requestOptions
  );
  return handleResponse(response);
};

const updateParticipant = async (eventParticipant) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(eventParticipant),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/EventParticipant/${eventParticipant.id}`,
    requestOptions
  );
  return handleResponse(response);
};

const confirmParticipant = async (id) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API}/api/EventParticipant/${id}/confirm`,
    requestOptions
  );
  return handleResponse(response);
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        history.push('/login');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
};

const handleGetParticipantsResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        history.push('/login');
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    const participants = Array.from(data).map((p) => {
      let formValuesData = p.formValues?.reduce(
        (a, x) => ({ ...a, [x.field.id]: x.value }),
        {}
      );

      if (p.reviewFormValues && p.reviewFormValues.length > 0) {
        formValuesData = Object.assign(
          {},
          formValuesData,
          p.reviewFormValues.reduce(
            (a, x) => ({
              ...a,
              [x.field.id]: x.value,
            }),
            {}
          )
        );
      }

      const part = {
        ...p,
        data: formValuesData,
      };

      return part;
    });

    return participants;
  });
};

export const participantsService = {
  getParticipants,
  deleteParticipant,
  updateParticipant,
  confirmParticipant,
  getAllParticipants,
};
