import React, {forwardRef, useEffect, useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import {Box, Collapse, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import EventsTableRecordDetails from "./EventsTableRecordDetails";
import Button from "@material-ui/core/Button";
import {authHeader} from "../../helpers";
import axios from "axios";
import MaterialTable from "material-table";
import Check from "@material-ui/icons/Check";
import {participantsActions} from "../../actions";
import AddBox from "@material-ui/icons/AddBox";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";

const useStyles = makeStyles(styles);

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function EventsTable(props) {
    const [events, setEventsData] = useState([]);
    const eventsApi = process.env.REACT_APP_API;
    const [newEventDialog, setNewEventDialog] = useState(false);
    const [forms, setForms] = useState({});
    
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        const fetchData = async () => {
            const forms = await axios(
                `${eventsApi}/api/Forms`, requestOptions
            );
            var formsDict = forms.data.reduce(             
                (obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            setForms((formsDict));

            const events = await axios(
                `${eventsApi}/api/Events`, requestOptions
            );
            setEventsData(events.data);
            // if(events.data.length > 0)
            //     setEventData(events.data[0]);
        };

        fetchData();
    }, []);
    
    
    const columns = [
        {        
            field: `shortName`,
            title: `Nazwa skrócona`,
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: `name`,
            title: `Nazwa pełna`,
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "from",
            title: "Data od",
            hidden: false,
            hiddenByColumnsButton: false,
            type: "date",
            dateSetting: { locale: "fr-FR"}
        },
        {
            field: "to",
            title: "Data do",
            hidden: false,
            hiddenByColumnsButton: false,
            type: "date"
        },
        {
            field: "place",
            title: "Miejsce",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "shift",
            title: "Turnus",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "availableParticipants",
            title: "Liczba miejsc",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "ageCategory",
            title: "Kategoria wiekowa",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "price",
            title: "Cena",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "cardGenerationEnabled",
            title: "Czy generować kartę uczestnika",
            hidden: false,
            hiddenByColumnsButton: false,
            lookup: { true: 'Tak', false: 'Nie' },
        },
        {
            field: "eventCardTemplate",
            title: "Szablon karty uczestnika",
            hidden: false,
            hiddenByColumnsButton: false
        },
        {
            field: "confirmationEmail",
            title: "Treść emaila potwierdzającego",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "formSubmittedMessage",
            title: "Treść emaila po wysłaniu formularza",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "reserveListEmail",
            title: "Treść emaila dla listy rezerwowej",
            hidden: false,
            hiddenByColumnsButton: false,
        },
        {
            field: "registrationFormId",
            title: "Formularz rejestracyjny",
            hidden: false,
            hiddenByColumnsButton: false,
            lookup: forms
        },
        {
            field: "isReviewRequest",
            title: "Opinia animatora",
            hidden: false,
            hiddenByColumnsButton: false,
            lookup: { true: 'Tak', false: 'Nie' },
        },
        {
            field: "reviewFormId",
            title: "Formularz do opinii animatora",
            hidden: false,
            hiddenByColumnsButton: false,
            lookup: forms
        }        
    ];

    const classes = useStyles();
    const {tableHead, tableData, tableHeaderColor, actions} = props;
    
    const addEvent = (event) => {
        const newEvents = events;
        newEvents.push(event);
        
        
        
        setEventsData(newEvents);
    };
    
    const removeEvent = (id) => {
        // const newData = data.filter((item) => item.id !== id);
        // setData(newData);
    }

    const updateEvent = (event) => {
        console.log(JSON.stringify(event));        
    }
    
    return (
        <React.Fragment>
            {/*<Button color="primary" onClick={()=>setNewEventDialog(true)}>*/}
            {/*    Dodaj rekolekcje*/}
            {/*</Button>*/}
            <MaterialTable
                title=""
                columns={columns}
                icons={tableIcons}
                data={events}
                // isLoading={participants.loading}
                style={{ zIndex: 1 }}
                options={{
                    // exportButton: true,
                    // exportDelimiter: ';',
                    // exportAllData: true,
                    columnsButton: true,
                    // exportFileName: 'listaUczestnikow',
                    headerStyle: {
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 300,
                    },
                    rowStyle: {
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                }}
                localization={{
                    body: {
                        editRow: {
                            deleteText: 'Czy na pewno chcesz usunąć to wydarzenie?',
                            cancelTooltip: 'Odrzuć zmiany',
                            saveTooltip: 'Zapisz zmiany',
                        },
                        editTooltip: 'Edytuj',
                        deleteTooltip: 'Usuń',
                        emptyDataSourceMessage: 'Brak danych do wyświetlenia',
                        addTooltip: 'Dodaj wydarzenie'
                    },
                    header: {
                        actions: 'Akcje',
                    },
                    pagination: {
                        firstAriaLabel: 'Pierwsza strona',
                        firstTooltip: 'Pierwsza strona',
                        nextAriaLabel: 'Następna strona',
                        nextTooltip: 'Następna strona',
                        labelRowsSelect: '',
                        labelDisplayedRows: '{from}-{to} z {count}',
                        lastAriaLabel: 'Ostatnia strona',
                        lastTooltip: 'Ostatnia strona',
                        previousAriaLabel: 'Poprzednia strona',
                        previousTooltip: 'Poprzednia strona',
                    },
                    toolbar: {
                        addRemoveColumns: 'Kolumny',
                        exportName: 'Zapisz jako CSV',
                        exportAriaLabel: 'Pobierz listę',
                        exportTitle: 'Pobierz listę',
                        searchTooltip: 'Szukaj',
                        searchPlaceholder: 'Szukaj',
                        showColumnsTitle: 'Pokaż kolumny',
                        showColumnsAriaLabel: 'Pokaż kolumny',
                    },
                }}
                actions={[
                    (rowData) => ({
                        icon: Check,
                        tooltip:
                            'Potwierdź (ustawia status zgłoszenia na "Potwierdzone" i wysyła maila z kartą zgłoszeniową)',
                        onClick: (event, rowData) =>
                            console.log("test"),
                    }),
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                
                                
                                /* setData([...data, newData]); */

                                resolve();
                            }, 1000);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const updatedData = {
                                    ...newData,
                                    advance: parseInt(newData.advance),
                                };

                                // dispatch(participantsActions.updateParticipant(updatedData));
                                resolve();
                            }, 1000);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                // dispatch(participantsActions.deleteParticipant(oldData.id));
                                resolve();
                            }, 1000);
                        }),
                }}
            />
            
        </React.Fragment>
    );
}