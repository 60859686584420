import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Form from 'components/Form/Form.js';
import axios from 'axios'
import avatar from 'assets/img/faces/marc.jpg';
import { useParams } from 'react-router-dom';
import {CircularProgress} from "@material-ui/core";

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

export default function Register() {
  const eventsApi = process.env.REACT_APP_API;
  const [event, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const events = await axios(
        `${eventsApi}/api/Events/${params.eventId}`
      );
      setData(events.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const classes = useStyles();

  if (event && event.id)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <Form event={event}></Form>
          </GridItem>
        </GridContainer>
      </div>
    );
  else if (isLoading)
    return <CircularProgress />
  else
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            Podano błędne dane
          </GridItem>
        </GridContainer>
      </div>
    );
}
