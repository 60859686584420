import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import { userActions } from '../../actions';
import Danger from "components/Typography/Danger.js";

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

export default function Login() {
  const [inputs, setInputs] = useState({
      username: '',
      password: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const error = useSelector(state => state.alert);
  const dispatch = useDispatch();

  // reset login status
  useEffect(() => {
      dispatch(userActions.logout());
  }, []);

  function handleChange(e){   
      const name = e.target.id;
      const value = e.target.value;
      setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
      e.preventDefault();

      setSubmitted(true);
      if (username && password) {
          dispatch(userActions.login(username, password));
      }
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={10} sm={7} md={6}>
        <form name="form" onSubmit={handleSubmit}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>System obsługi rekolekcji oazowych - Logowanie</h4>
            </CardHeader>
            <CardBody>
              {error && <Danger> {error.message} </Danger> }              
              <GridContainer>                
                <GridItem xs={12} sm={12} md={12}>         
                  <CustomInput
                    labelText="Nazwa użytkownika"
                    id="username"                                        
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: username
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Hasło"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: handleChange,
                      value: password,
                      type: 'password',
                    }}
                  />
                </GridItem>
              </GridContainer>
              
            </CardBody>
            <CardFooter>
              <Button type="submit" color="primary" >Zaloguj się {loggingIn && <span className="spinner-border spinner-border-sm mr-1"></span>}</Button>
            </CardFooter>
          </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
