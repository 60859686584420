import React, {useState, useEffect} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// core components
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import Success from 'components/Typography/Success.js';
import Danger from 'components/Typography/Danger.js';
import GridContainer from '../Grid/GridContainer';
import {Box, CircularProgress} from '@material-ui/core';
import GridItem from '../Grid/GridItem';
import FormField from "../Form/FormField";
import Grid from "@material-ui/core/Grid";

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
    sectionTitle: {
        borderBottom: '1px solid #1C6C8C',
        color: '#1C6C8C',
        fontWeight: 400,
        margin: 0,
    },
};

const useStyles = makeStyles(styles);

export default function ReviewForm(props) {
    const {
        register,
        errors,
        handleSubmit,
        formState: {isSubmitting},
        control,
        setValue,
    } = useForm();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [sending, setSending] = useState(null);

    const {eventParticipantId, formId} = props;
    const classes = useStyles();

    const eventsApi = process.env.REACT_APP_API;
    const [eventForm, setData] = useState(null);
    const [eventParticipant, setEventParticipant] = useState(null);

    useEffect(() => {
        const fetchParticipant = async () => {
            const participant = await axios(`${eventsApi}/api/EventParticipant/GetParticipantForReview/${props.reviewerKey}`);
            setEventParticipant(participant.data);
            fetchForm(participant.data.reviewFormId);
        };

        const fetchForm = async (reviewFormId) => {
            const form = await axios(`${eventsApi}/api/Forms/${reviewFormId}`);
            setData(form.data);
        };

        fetchParticipant();
    }, []);

    const onSubmit = (data) => {
        setSuccess(null);
        setError(null);
        setSending(true);

        const dataToSend = Object.entries(data)
            .map(([key, value]) => ({key: key, value: value?.toString()}));

        const body = JSON.stringify({
            eventParticipantId: eventParticipant.id,
            fields: dataToSend,
        });
        fetch(eventsApi + `/api/EventParticipant/${eventParticipant.id}/SendReview/${props.reviewerKey}`, {
            method: 'POST',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: body,
        })
            .then((result) => {
                if (result.ok) {
                    return result
                        .json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            const {message} = json;
                            setSuccess(message);
                            setError(null);
                        });
                } else {
                    return result
                        .json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            const {error, stackTrace} = json;
                            if (error.length > 0) setError(error);
                            else
                                setError(
                                    'Wystąpił błąd, prosimy o kontakt z administracją.'
                                );
                            setSuccess(null);
                        });
                }
            })
            .finally(() => setSending(false));
    };

    function renderReviewForm() {
        return <GridContainer>
            <GridItem xs={12}>
                <label>Pola oznaczane * są wymagane</label>
            </GridItem>
            {Object.keys(eventForm).map((key) => {
                let section = eventForm[key];

                return (
                    <React.Fragment>
                        <GridItem xs={12} pt={2}>
                            <Box pt={6}>
                                <h4
                                    className={
                                        classes.sectionTitle
                                    }
                                >
                                    {section.name}
                                </h4>
                            </Box>
                        </GridItem>
                        {Object.keys(section.fields).map(
                            (key) => {
                                let field = section.fields[key];
                                return (
                                    <FormField
                                        displayName={`${
                                            field.required
                                                ? '* '
                                                : ''
                                        }${field.displayName}`}
                                        name={field.key}
                                        type={field.type}
                                        width={field.width}
                                        required={
                                            field.required
                                        }
                                        register={register}
                                        control={control}
                                        data={
                                            field.formFieldItems
                                        }
                                        error={
                                            errors[field.key]
                                        }
                                        setValue={setValue}
                                    />
                                );
                            }
                        )}
                    </React.Fragment>
                );
            })}
        </GridContainer>;
    }

    return (eventParticipant ? (<Card>
                <form className="App" onSubmit={handleSubmit(onSubmit)}>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>
                            Opinia dotycząca zgłoszenia
                        </h4>
                        <p className={classes.cardCategoryWhite}>
                            {eventParticipant.name} {eventParticipant.surname}, rekolekcje - {eventParticipant.eventName}
                        </p>
                    </CardHeader>
                    <CardBody>
                        {eventParticipant.reviewExists ? (<GridContainer>
                                <GridItem xs={12}>
                                    Opinia już została wysłana.
                                </GridItem>
                            </GridContainer>) :
                            eventForm ? renderReviewForm() : (
                                <CircularProgress/>
                            )
                        }
                    </CardBody>
                    <CardFooter>
                        {eventParticipant.reviewExists ? null :
                            (<Button color="primary" type="submit" disabled={sending}>
                                {sending ? 'Wysyłam...' : 'Wyślij'}
                            </Button>)
                        }
                        {error && <Danger>{error}</Danger>}
                        {success && <Success>{success}</Success>}
                    </CardFooter>
                </form>
            </Card>)
            : <Grid container justify="center">
                <CircularProgress/>
            </Grid>

    );
}
