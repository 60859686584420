import React from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Accessibility from '@material-ui/icons/Accessibility';
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
import ChildCare from '@material-ui/icons/ChildCare';
import Group from '@material-ui/icons/Group';
import Person from '@material-ui/icons/Person';
import House from '@material-ui/icons/House';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import EventsTable  from 'components/EventsTable/EventsTable.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { bugs, website, server } from 'variables/general.js';

import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart,
} from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function EventsList() {
    const classes = useStyles();
    return (
        <div>           
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title="Kategoria:"
                        headerColor="primary"
                        tabs={[
                            {
                                tabName: 'Dzieci',
                                tabIcon: ChildCare,
                                tabContent: (
                                    <EventsTable
                                        tableHeaderColor="primary"
                                        tableHead={[ "Nazwa skrócona",
                                            "Nazwa pełna",
                                            "Data od",
                                            "Data do",
                                            "Miejsce",
                                            "Turnus",
                                            "Liczba miejsc",
                                            "Wiadomość potwierdzająca (treść)",
                                            "Wiadomość lista rezerwowa (treść)",
                                            "Kolejność",
                                            "Czy aktywne",
                                            "Kategoria wiekowa",
                                            "Cena",
                                            "Typ rekolekcji",
                                            "Szablon karty rekolekcyjnej",
                                            "Czy generować kartę rekolekcyjną",
                                            "Mail do organizatora",
                                            "Szablon formularza rekolekcyjnego",
                                            "Automatyczne potwierdzenie zgłoszenia"]}
                                        tableData={[
                                            ['1', 'Dakota Rice', '$36,738', 'Niger'],
                                            ['2', 'Minerva Hooper', '$23,789', 'Curaçao'],
                                            ['3', 'Sage Rodriguez', '$56,142', 'Netherlands'],
                                            ['4', 'Philip Chaney', '$38,735', 'Korea, South'],
                                        ]}
                                        actions={true}
                                    />
                                ),
                            },
                            {
                                tabName: 'Młodzież',
                                tabIcon: Group,
                                tabContent: (
                                    <EventsTable
                                        tableHeaderColor="primary"
                                        category={'mlodziez'}
                                    />
                                ),
                            },
                            {
                                tabName: 'Domowy Kościół',
                                tabIcon: House,
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[0]}
                                        tasksIndexes={[0, 1]}
                                        tasks={website}
                                    />
                                ),
                            },
                            {
                                tabName: 'Inne',
                                tabIcon: Person,
                                tabContent: (
                                    <Tasks
                                        checkedIndexes={[1]}
                                        tasksIndexes={[0, 1, 2]}
                                        tasks={server}
                                    />
                                ),
                            },
                        ]}
                    />
                </GridItem>               
            </GridContainer>
        </div>
    );
}
