import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';
import { Controller } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(styles);

export default function MultiSelect(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        error,
        success,
        type,
        control,
        data,
        setValue
    } = props;

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error,
    });

    React.useEffect(() => {
        setValue(id, []);
    }, [setValue]);
     
    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + ' ' + classes.formControl}
            error={error}
        >
            {labelText !== undefined && type !== 'multiselect' ? (
                labelText.length < 50 ? (
                    <InputLabel
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : (
                    <label
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </label>
                )
            ) : null}
            { <Controller
                render={(props) => ( <Autocomplete
                        {...props}
                        multiple
                        id="checkboxes-tags-demo"
                        options={data}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.displayName}
                        getOptionSelected={(option, value) => {
                            const compVal = data.find(
                                (op) => op === value
                            );

                            if (compVal)
                                return option.value === compVal.value;
                        }}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    color="primary"
                                    checked={selected}
                                />
                                {option.displayName}
                            </React.Fragment>
                        )}
                        style={{ width: 500 }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    label={labelText}
                                    placeholder=""
                                />
                            );
                        }}
                        onChange={(e, values) => {
                            const displayValues = values.map(value => value.displayName);
                            setValue(id, displayValues);
                        }}
                    />

                )}
                name={id}
                control={control}
            />}

            {error ? (
                <Clear
                    className={classes.feedback + ' ' + classes.labelRootError}
                />
            ) : success ? (
                <Check
                    className={
                        classes.feedback + ' ' + classes.labelRootSuccess
                    }
                />
            ) : null}
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
    );
}

MultiSelect.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
};
