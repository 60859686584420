import React, {useState} from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import DateFnsUtils from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';
// @material-ui/core components
import Checkbox from '@material-ui/core/Checkbox';
// core components
import ReactHtmlParser from 'react-html-parser';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import {Controller} from 'react-hook-form';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};

const useStyles = makeStyles(styles);

export default function FormField(props) {
    const {
        displayName,
        name,
        type,
        width,
        required,
        selectItems,
        control,
        register,
        data,
        error,
        setValue,
    } = props;

    const [fromSelectedDate, setFromSelectedDate] = useState(new Date());

    const classes = useStyles();

    const dateUtils = new DateFnsUtils();

    switch (type) {
        case 'date':

            return (
                <GridItem
                    xs={12}
                    sm={12}
                    md={displayName.length < 50 ? width : 12}
                >
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={plLocale}
                    >
                        <Controller
                            name={name}
                            control={control}
                            render={(props) => (
                                <DatePicker
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label={`${displayName} (format DD/MM/RRRR)`}
                                    format="dd/MM/yyyy"
                                    {...props}
                                    value={fromSelectedDate}
                                    onChange={date => {
                                        setFromSelectedDate(date);
                                        props.onChange(dateUtils.format(date, 'dd/MM/yyyy'));
                                    }}
                                    fullWidth={true}
                                />
                            )}
                            defaultValue={dateUtils.format(fromSelectedDate, 'dd/MM/yyyy')}
                        />
                    </MuiPickersUtilsProvider>
                </GridItem>
            );
        case 'string':
            return (
                <GridItem
                    xs={12}
                    sm={12}
                    md={displayName.length < 50 ? width : 12}
                >
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            inputRef: register({
                                required: required ? 'To pole jest wymagane.' : false,
                            }),
                            name: name,
                            type: type,
                        }}
                        setValue={setValue}
                    />
                </GridItem>
            );
        case 'email':
            return (
                <GridItem
                    xs={12}
                    sm={12}
                    md={displayName.length < 50 ? width : 12}
                >
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            inputRef: register({
                                required: required ? 'To pole jest wymagane.' : false,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Niepoprawny format adresu email",
                                },
                            }),
                            name: name,
                            type: 'string',
                        }}
                        setValue={setValue}
                    />
                </GridItem>
            );


        case 'number':
            return (
                <GridItem
                    xs={12}
                    sm={12}
                    md={displayName.length < 50 ? width : 12}
                >
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            inputRef: register({
                                required: required ? 'To pole jest wymagane.' : false,
                            }),
                            name: name,
                            type: type,
                        }}
                        setValue={setValue}
                    />
                </GridItem>
            );
        case 'multilinestring':
            return (
                <GridItem
                    xs={12}
                    sm={12}
                    md={displayName.length < 50 ? width : 12}
                >
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            inputRef: register({
                                required: required ? 'To pole jest wymagane.' : false,
                            }),
                            name: name,
                            type: 'string',
                            multiline: true,
                        }}
                        setValue={setValue}
                    />
                </GridItem>
            );
        case 'checkbox':
            return (
                <GridItem xs={12} sm={12} md={width}>
                    <Checkbox
                        inputRef={register({
                            required: required ? 'To pole jest wymagane.' : false,
                        })}
                        inputProps={{
                            name: name,
                            type: type,
                        }}
                        color="primary"
                        classes={{
                            checked: classes.checked,
                            root: classes.root,
                        }}
                        error={true}
                    />
                    {ReactHtmlParser(displayName)}
                </GridItem>
            );
        case 'select':
            return (
                <GridItem xs={12} sm={12} md={width}>
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        type={type}
                        items={selectItems}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            inputRef: register({
                                required: required ? 'To pole jest wymagane.' : false,
                            }),
                            name: name,
                            type: type,
                        }}
                        control={control}
                        data={data}
                        setValue={setValue}
                    ></CustomInput>
                </GridItem>
            );
        case 'multiselect':
            return (
                <GridItem xs={12} sm={12} md={width}>
                    <CustomInput
                        error={error}
                        labelText={displayName}
                        id={name}
                        type={type}
                        items={selectItems}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputRef={register({
                            required: required ? 'To pole jest wymagane.' : false,
                        })}
                        inputProps={{
                            name: name,
                            type: type,
                        }}
                        control={control}
                        data={data}
                        setValue={setValue}
                    ></CustomInput>
                </GridItem>
            );
        default:
            return;
    }
}
