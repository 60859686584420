import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { participantsActions } from '../../actions/participants.actions';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ParticipantsList = (props) => {
  const commonColumns = [
    { field: 'name', title: 'Imię' },
    { field: 'surname', title: 'Nazwisko' },
    { field: 'email', title: 'Adres email' },
    { field: 'phoneNumber', title: 'Nr. telefonu' },
    {
      field: 'reviewRequestEmail',
      title: 'Adres email do opinii',
      hidden: true,
      hiddenByColumnsButton: true,
    },
    {
      field: 'registrationStatus',
      title: 'Status zgłoszenia',
      lookup: {
        1: 'Lista rezerwowa',
        2: 'Potwierdzone',
        3: 'Zapisane',
        4: 'Czeka na opinie',
        5: 'Opinia przekazana'
      },
    },
    { field: 'advance', title: 'Zaliczka' },
    { field: 'notes', title: 'Notatki' },
  ];

  const participants = useSelector((state) => state.participants);

  const [columns, setColumns] = useState(commonColumns);

  const eventsApi = process.env.REACT_APP_API;

  useEffect(() => {
    const fetchData = async () => {
      await axios(`${eventsApi}/api/Forms/${props.registrationFormId}`).then(
        (response) => {
          let registerFormColumns = [];

          response.data.map((section) => {
            section.fields.map((field) => {
              if (field.type === 'checkbox') {
                registerFormColumns.push({
                  field: `data[${field.id}]`,
                  title: `${field.displayName.substring(0, 25)}${
                    field.displayName.length > 25 ? '...' : ''
                  }`,
                  hidden: true,
                  hiddenByColumnsButton: true,
                  lookup: { true: 'Tak', false: 'Nie' },
                  editable: false,
                });
              } else {
                registerFormColumns.push({
                  field: `data[${field.id}]`,
                  title: `${field.displayName.substring(0, 25)}${
                    field.displayName.length > 25 ? '...' : ''
                  }`,
                  hidden: true,
                  hiddenByColumnsButton: true,
                });
              }
            });
          });

          const newColumns = [...columns, ...registerFormColumns];

          if (props.reviewFormId) {
            fetchReview(newColumns);
          } else {
            setColumns(newColumns);
          }
        }
      );
    };

    const fetchReview = async (newColumns) => {
      await axios(`${eventsApi}/api/Forms/${props.reviewFormId}`).then(
        (response) => {
          response.data.map((section) => {
            section.fields.map((field) => {
              if (field.type === 'checkbox') {
                newColumns.push({
                  field: `data[${field.id}]`,
                  title: `${field.displayName.substring(0, 25)}${
                      field.displayName.length > 25 ? '...' : ''
                  }`,
                  hidden: true,
                  hiddenByColumnsButton: true,
                  lookup: { true: 'Tak', false: 'Nie' },
                  editable: false,
                });
              } else {
                newColumns.push({
                  field: `data[${field.id}]`,
                  title: `${field.displayName.substring(0, 25)}${
                      field.displayName.length > 25 ? '...' : ''
                  }`,
                  hidden: true,
                  hiddenByColumnsButton: true,
                });
              }
              
             
            });
          });

          setColumns(newColumns);
        }
      );
    };

    if (props.eventId) fetchData();
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.eventId) {
      dispatch(participantsActions.getParticipants(props.eventId));
      return;
    }

    const newColumns = [...columns];

    newColumns.splice(0, 0, {
      editable: false,
      field: 'event.name',
      title: 'Rekolekcje',
    });

    setColumns(newColumns);
    dispatch(participantsActions.getAllParticipants());
  }, []);

  return (
    <div key={props.eventId} className="card participants">
      {participants.error && (
        <span className="text-danger">ERROR: {participants.error}</span>
      )}
      {columns && (
        <MaterialTable
          title=""
          columns={columns}
          icons={tableIcons}
          data={participants.items}
          isLoading={participants.loading}
          style={{ zIndex: 1 }}
          options={{
            exportButton: true,
            exportDelimiter: ';',
            exportAllData: true,
            columnsButton: true,
            exportFileName: 'listaUczestnikow',
            headerStyle: {
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 300,
            },
            rowStyle: {
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          localization={{
            body: {
              editRow: {
                deleteText: 'Czy na pewno chcesz usunąć to zgłoszenie?',
                cancelTooltip: 'Odrzuć zmiany',
                saveTooltip: 'Zapisz zmiany',
              },
              editTooltip: 'Edytuj',
              deleteTooltip: 'Usuń',
              emptyDataSourceMessage: 'Brak danych do wyświetlenia',
            },
            header: {
              actions: 'Akcje',
            },
            pagination: {
              firstAriaLabel: 'Pierwsza strona',
              firstTooltip: 'Pierwsza strona',
              nextAriaLabel: 'Następna strona',
              nextTooltip: 'Następna strona',
              labelRowsSelect: '',
              labelDisplayedRows: '{from}-{to} z {count}',
              lastAriaLabel: 'Ostatnia strona',
              lastTooltip: 'Ostatnia strona',
              previousAriaLabel: 'Poprzednia strona',
              previousTooltip: 'Poprzednia strona',
            },
            toolbar: {
              addRemoveColumns: 'Kolumny',
              exportName: 'Zapisz jako CSV',
              exportAriaLabel: 'Pobierz listę',
              exportTitle: 'Pobierz listę',
              searchTooltip: 'Szukaj',
              searchPlaceholder: 'Szukaj',
              showColumnsTitle: 'Pokaż kolumny',
              showColumnsAriaLabel: 'Pokaż kolumny',
            },
          }}
          actions={[
            (rowData) => ({
              icon: Check,
              tooltip:
                'Potwierdź (ustawia status zgłoszenia na "Potwierdzone" i wysyła maila z kartą zgłoszeniową)',
              onClick: (event, rowData) =>
                dispatch(participantsActions.confirmParticipant(rowData.id)),
            }),
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const updatedData = {
                    ...newData,
                    advance: parseInt(newData.advance),
                  };

                  dispatch(participantsActions.updateParticipant(updatedData));
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  dispatch(participantsActions.deleteParticipant(oldData.id));
                  resolve();
                }, 1000);
              }),
          }}
        />
      )}
    </div>
  );
};

export default ParticipantsList;
