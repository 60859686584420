import { participantsConstants } from '../constants';
import { participantsService } from '../services';
import { store } from 'react-notifications-component';

export const participantsActions = {
  getAllParticipants,
  getParticipants,
  deleteParticipant,
  updateParticipant,
  confirmParticipant,
};

const notifcation = {
  insert: 'top',
  container: 'top-center',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 2000,
  },
};

function getAllParticipants() {
  return (dispatch) => {
    dispatch(request());

    participantsService.getAllParticipants().then(
      (participants) => dispatch(success(participants)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: participantsConstants.GETPARTICIPANTS_REQUEST,
    };
  }

  function success(participants) {
    return {
      type: participantsConstants.GETPARTICIPANTS_SUCCESS,
      participants,
    };
  }

  function failure(error) {
    return { type: participantsConstants.GETPARTICIPANTS_FAILURE, error };
  }
}

function getParticipants(eventId) {
  return (dispatch) => {
    dispatch(request(eventId));

    participantsService.getParticipants(eventId).then(
      (participants) => dispatch(success(participants)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request(eventId) {
    return { type: participantsConstants.GETPARTICIPANTS_REQUEST, eventId };
  }
  function success(participants) {
    return {
      type: participantsConstants.GETPARTICIPANTS_SUCCESS,
      participants,
    };
  }
  function failure(error) {
    return { type: participantsConstants.GETPARTICIPANTS_FAILURE, error };
  }
}

function deleteParticipant(id) {
  return (dispatch) => {
    dispatch(request(id));

    participantsService.deleteParticipant(id).then(
      (participant) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: participantsConstants.DELETEPARTICIPANT_REQUEST, id };
  }
  function success(id) {
    store.addNotification({
      ...notifcation,
      message: 'Rekord usunięty',
      type: 'info',
    });
    return { type: participantsConstants.DELETEPARTICIPANT_SUCCESS, id };
  }
  function failure(id, error) {
    store.addNotification({
      ...notifcation,
      message: 'Błąd podczas usuwania',
      type: 'danger',
    });
    return { type: participantsConstants.DELETEPARTICIPANT_FAILURE, id, error };
  }
}

function updateParticipant(eventParticipant) {
  return (dispatch) => {
    dispatch(request(eventParticipant));

    participantsService.updateParticipant(eventParticipant).then(
      () => dispatch(success(eventParticipant)),
      (error) => dispatch(failure(eventParticipant.id, error.toString()))
    );
  };

  function request(participant) {
    return {
      type: participantsConstants.UPDATEPARTICIPANT_REQUEST,
      participant,
    };
  }
  function success(participant) {
    store.addNotification({
      ...notifcation,
      message: 'Zaktualizowano pomyślnie',
      type: 'info',
    });
    return {
      type: participantsConstants.UPDATEPARTICIPANT_SUCCESS,
      participant,
    };
  }
  function failure(error) {
    store.addNotification({
      ...notifcation,
      message: 'Błąd podczas aktualizowania',
      type: 'danger',
    });
    return { type: participantsConstants.UPDATEPARTICIPANT_FAILURE, error };
  }
}

function confirmParticipant(id) {
  return (dispatch) => {
    dispatch(request(id));

    participantsService.confirmParticipant(id).then(
      (participant) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: participantsConstants.CONFIRMPARTICIPANT_REQUEST, id };
  }
  function success(id) {
    store.addNotification({
      ...notifcation,
      message: 'Zgłoszenie potwierdzone',
      type: 'info',
    });
    return { type: participantsConstants.CONFIRMPARTICIPANT_SUCCESS, id };
  }
  function failure(id, error) {
    store.addNotification({
      ...notifcation,
      message: 'Błąd podczas potwierdzania',
      type: 'danger',
    });
    return {
      type: participantsConstants.CONFIRMPARTICIPANT_FAILURE,
      id,
      error,
    };
  }
}
