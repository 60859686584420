import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import ParticipantsList from 'components/ParticipantsList/ParticipantsList';
import axios from 'axios';
import moment from 'moment';
import EventsFilter from 'components/EventsFilter/EventsFilter.js';
import { authHeader } from '../../helpers';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

const TableList = () => {
  const classes = useStyles();

  const [event, setEventData] = useState(null);
  const [isReserveList, setReserveList] = React.useState(false);
  const [events, setEventsData] = useState([]);
  const eventsApi = process.env.REACT_APP_API;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    const fetchData = async () => {
      const events = await axios(
        `${eventsApi}/api/Events/GetAvailableEvents/${false}`,
        requestOptions
      );
      setEventsData(events.data);
      if (events.data.length > 0) setEventData(events.data[0]);
    };

    fetchData();
  }, []);

  const eventSelected = (id) => {
    if (id) {
      const e = events.find((x) => x.id === id)
      setEventData(e);
      setReserveList(e.isReserveList);
      return;
    }

    setEventData({
      id: 0,
      name: 'wyświetl wszystkich uczestników',
    });
    
  };

  const handleChange = (e) => {
    const newStatus = e.target.checked;
    fetch(eventsApi + `/api/Events/${event.id}/ChangeReserveListStatus/${newStatus}`, {
      method: 'POST',
      headers: authHeader()
    }).then((result) => {
      if(result.ok)
        setReserveList(newStatus);
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitleWhite}>
                {event
                  ? `${event.name}${event.id ? ` - ${event.place}` : ''}`
                  : ''}
                <EventsFilter events={events} eventSelected={eventSelected} />
              </h4>
              <p className={classes.cardCategoryWhite}>
                {event && event.id
                  ? `${moment(event.from).format('DD.MM')} - ${moment(
                      event.to
                    ).format('DD.MM.YYYY')}`
                  : ''}
              </p>
            </GridItem>
          </CardHeader>
          <CardBody>
            {event && event.id > 0 && (
                <FormGroup row>
                  <FormControlLabel
                      control={
                        <Switch
                            checked={isReserveList}
                            onChange={handleChange}
                            color="primary"
                            name="isReserveList"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Lista rezerwowa (Uwaga! zmiana tego przełącznika włącza/wyłącza listę rezerwową dla wydarzenia)"
                  />
                </FormGroup>
            )}           
     
            {event && (
              <ParticipantsList
                key={event.id}
                eventId={event.id}
                registrationFormId={event.registrationFormId}
                reviewFormId={event.reviewFormId}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TableList;
