import React from 'react';

import classNames from 'classnames';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Poppers from '@material-ui/core/Popper';

import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(styles);

export const GET_ALL_PARTICIPANTS_VALUE = 0;

const EventsFilter = (props) => {
  const [openNotification, setOpenNotification] = React.useState(null);
  const classes = useStyles();

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleClick = (e) => {
    setOpenNotification(null);
    props.eventSelected(e.target.value);
  };

  function generateMenuListItem(items) {
    return Object.keys(items).map((key) => {
      const field = items[key];
      return (
        <MenuItem
          onClick={handleClick}
          className={classes.dropdownItem}
          value={field.id}
          key={key}
        >
          {field.name} - {field.place} - turnus {field.shift} -{' '}
          {moment(field.from).format('DD.MM')} -{' '}
          {moment(field.to).format('DD.MM.YYYY')}
        </MenuItem>
      );
    });
  }

  return (
    <div className={classes.manager}>
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openNotification ? 'notification-menu-list-grow' : null}
        aria-haspopup="true"
        onClick={handleClickNotification}
        className={classes.buttonLink}
      >
        <ExpandMore className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p onClick={handleCloseNotification} className={classes.linkText}></p>
        </Hidden>
      </Button>
      <Poppers
        open={Boolean(openNotification)}
        anchorEl={openNotification}
        transition
        disablePortal
        placement="bottom-start"
        className={
          classNames({ [classes.popperClose]: !openNotification }) +
          ' ' +
          classes.popperNav
        }
        tooltip
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="notification-menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList role="menu">
                  <MenuItem
                    onClick={handleClick}
                    className={classes.dropdownItem}
                    value={GET_ALL_PARTICIPANTS_VALUE}
                  >
                    wyświetl wszystkich uczestników
                  </MenuItem>
                  {generateMenuListItem(props.events)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>
    </div>
  );
};

export default EventsFilter;
