import { participantsConstants } from '../constants';

export function participants(state = {}, action) {
  switch (action.type) {
    case participantsConstants.GETPARTICIPANTS_REQUEST:
      return {
        loading: true,
      };
    case participantsConstants.GETPARTICIPANTS_SUCCESS:
      return {
        items: action.participants,
      };
    case participantsConstants.GETPARTICIPANTS_FAILURE:
      return {
        error: action.error,
      };

    case participantsConstants.DELETEPARTICIPANT_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((participant) =>
          participant.id === action.id
            ? { ...participant, deleting: true }
            : participant
        ),
      };
    case participantsConstants.DELETEPARTICIPANT_SUCCESS:
      return {
        ...state,
        items: state.items.filter(
          (participant) => participant.id !== action.id
        ),
      };
    case participantsConstants.DELETEPARTICIPANT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((participant) => {
          if (participant.id === action.id) {
            // make copy of participant without 'deleting:true' property
            const { deleting, ...participantCopy } = participant;
            // return copy of user with 'deleteError:[error]' property
            return { ...participantCopy, deleteError: action.error };
          }

          return participant;
        }),
      };

    case participantsConstants.UPDATEPARTICIPANT_REQUEST:
      return {
        ...state,
      };
    case participantsConstants.UPDATEPARTICIPANT_SUCCESS:
      return {
        items: state.items.map((participant) =>
          participant.id ===
          action.participant.id
            ? { ...action.participant }
            : participant
        ),
      };
    case participantsConstants.UPDATEPARTICIPANT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case participantsConstants.CONFIRMPARTICIPANT_REQUEST:
      return {
        ...state,
      };
    case participantsConstants.CONFIRMPARTICIPANT_SUCCESS:
      return {
        ...state,
        items: state.items.map((participant) =>
          participant.id === action.id
            ? { ...participant, registrationStatus: 2 }
            : participant
        ),
      };
    case participantsConstants.CONFIRMPARTICIPANT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
