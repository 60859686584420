import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import Success from 'components/Typography/Success.js';
import Danger from 'components/Typography/Danger.js';
import GridContainer from '../Grid/GridContainer';
import FormField from './FormField';
import { Box, CircularProgress } from '@material-ui/core';
import GridItem from '../Grid/GridItem';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
    sectionTitle: {
        borderBottom: '1px solid #1C6C8C',
        color: '#1C6C8C',
        fontWeight: 400,
        margin: 0,
    },
};

const useStyles = makeStyles(styles);

export default function Form(props) {
    const {
        register,
        errors,
        handleSubmit,
        formState: { isSubmitting },
        control,
        setValue,
    } = useForm();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [sending, setSending] = useState(false);

    const { event } = props;
    const classes = useStyles();

    const eventsApi = process.env.REACT_APP_API;
    const [eventForm, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const form = await axios(`${eventsApi}/api/Forms/GetFormForEvent/${event.id}`);
            setData(form.data);
        };

        fetchData();
    }, []);
    
    const onSubmit = (data) => {
        if(sending === false) {
            setSuccess(null);
            setError(null);
            setSending(true);

            const dataToSend = Object.entries(data)
                .filter(
                    ([key, value]) =>
                        key !== 'name' &&
                        key !== 'surname' &&
                        key !== 'email' &&
                        key !== 'phoneNumber' &&
                        key !== 'reviewRequestEmail'
                )
                .map(([key, value]) => ({key: key, value: value?.toString()}));

            const body = JSON.stringify({
                eventId: event.id,
                name: data.name,
                surname: data.surname,
                email: data.email,
                phoneNumber: data.phoneNumber,
                reviewRequestEmail: data.reviewRequestEmail,
                fields: dataToSend,
            });
            fetch(eventsApi + '/api/EventParticipant/Register', {
                method: 'POST',
                headers: {
                    accept: '*/*',
                    'Content-Type': 'application/json',
                },
                body: body,
            })
                .then((result) => {
                    if (result.ok) {
                        return result
                            .json() // return the result of the inner promise, which is an error
                            .then((json) => {
                                const {message} = json;
                                setSuccess(message);
                                setError(null);
                            });
                    } else {
                        return result
                            .json() // return the result of the inner promise, which is an error
                            .then((json) => {
                                const {error, stackTrace} = json;
                                if (error.length > 0) setError(error);
                                else
                                    setError(
                                        'Wystąpił błąd, prosimy o kontakt z administracją.'
                                    );
                                setSuccess(null);
                            });
                    }
                })
                .finally(() => setSending(false));
        }
    };

    return (
        <Card>
            <form className="App" onSubmit={handleSubmit(onSubmit)}>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>
                        Formularz zgłoszeniowy
                    </h4>
                    <p className={classes.cardCategoryWhite}>
                        {event.name}, {event.place},{' '}
                        {moment(event.from).format('DD.MM')} -{' '}
                        {moment(event.to).format('DD.MM.YYYY')}
                    </p>
                </CardHeader>
                <CardBody>
                    {eventForm ? (
                        <GridContainer>
                            <GridItem xs={12}>
                                <label>Pola oznaczane * są wymagane</label>
                            </GridItem>
                            <FormField
                                displayName="* Imię"
                                name="name"
                                type="string"
                                width={6}
                                required={true}
                                register={register}
                                error={errors['name']}
                                setValue={setValue}
                            />
                            <FormField
                                displayName="* Nazwisko"
                                name="surname"
                                type="string"
                                width={6}
                                required={true}
                                register={register}
                                error={errors['surname']}
                                setValue={setValue}
                            />
                            <FormField
                                displayName="* Adres email"
                                name="email"
                                type="email"
                                width={6}
                                required={true}
                                register={register}
                                error={errors['email']}
                                setValue={setValue}
                            />
                            <FormField
                                displayName="* Numer telefonu"
                                name="phoneNumber"
                                type="string"
                                width={6}
                                required={true}
                                register={register}
                                error={errors['phoneNumber']}
                                setValue={setValue}
                            />                          

                            {Object.keys(eventForm).map((key) => {
                                let section = eventForm[key];

                                return (
                                    <React.Fragment>
                                        <GridItem xs={12} pt={2}>
                                            <Box pt={6}>
                                                <h4
                                                    className={
                                                        classes.sectionTitle
                                                    }
                                                >
                                                    {section.name}
                                                </h4>
                                            </Box>
                                        </GridItem>
                                        {Object.keys(section.fields).map(
                                            (key) => {
                                                let field = section.fields[key];
                                                return (
                                                    <FormField
                                                        displayName={`${
                                                            field.required
                                                                ? '* '
                                                                : ''
                                                        }${field.displayName}`}
                                                        name={field.key}
                                                        type={field.type}
                                                        width={field.width}
                                                        required={
                                                            field.required
                                                        }
                                                        register={register}
                                                        control={control}
                                                        data={
                                                            field.formFieldItems
                                                        }
                                                        error={
                                                            errors[field.key]
                                                        }
                                                        setValue={setValue}
                                                    />
                                                );
                                            }
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </GridContainer>
                    ) : (
                        <CircularProgress />
                    )}
                </CardBody>
                <CardFooter>
                    <Button color="primary" type="submit" disabled={sending}>
                        {sending ? 'Wysyłam...' : 'Wyślij'}
                    </Button>
                    {error && <Danger>{error}</Danger>}
                    {success && <Success>{success}</Success>}
                </CardFooter>
            </form>
        </Card>
    );
}
