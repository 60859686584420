export const participantsConstants = {
  GETPARTICIPANTS_REQUEST: 'GETPARTICIPANTS_REQUEST',
  GETPARTICIPANTS_SUCCESS: 'GETPARTICIPANTS_SUCCESS',
  GETPARTICIPANTS_FAILURE: 'GETPARTICIPANTS_FAILURE',

  DELETEPARTICIPANT_REQUEST: 'DELETEPARTICIPANT_REQUEST',
  DELETEPARTICIPANT_SUCCESS: 'DELETEPARTICIPANT_SUCCESS',
  DELETEPARTICIPANT_FAILURE: 'DELETEPARTICIPANT_FAILURE',

  UPDATEPARTICIPANT_REQUEST: 'UPDATEPARTICIPANT_REQUEST',
  UPDATEPARTICIPANT_SUCCESS: 'UPDATEPARTICIPANT_SUCCESS',
  UPDATEPARTICIPANT_FAILURE: 'UPDATEPARTICIPANT_FAILURE',

  CONFIRMPARTICIPANT_REQUEST: 'CONFIRMPARTICIPANT_REQUEST',
  CONFIRMPARTICIPANT_SUCCESS: 'CONFIRMPARTICIPANT_SUCCESS',
  CONFIRMPARTICIPANT_FAILURE: 'CONFIRMPARTICIPANT_FAILURE',

  GET_ALL_PARTICIPANTS_REQUEST: 'GET_ALL_PARTICIPANTS_REQUEST',
  GET_ALL_PARTICIPANTS_SUCCESS: 'GET_ALL_PARTICIPANTS_SUCCESS',
  GET_ALL_PARTICIPANTS_FAILURE: 'GET_ALL_PARTICIPANTS_FAILURE',
};
