import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Collapse, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import CustomInput from "../CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";
import CardFooter from "../Card/CardFooter";
import Button from "../CustomButtons/Button";
import CardAvatar from "../Card/CardAvatar";
import avatar from "../../assets/img/faces/marc.jpg";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};

const useStyles = makeStyles(styles);

export default function EventsTableRecordDetails(props) {
    const classes = useStyles();

    const createEmptyDataObject = () => {
        return {
            shortName: '',
            name: '',
            from: '',
            to: '',
            place: '',
            shift: '',
            maxParticipants: '',
            active: false,
            ageCategory: '',
            cardGenerationEnabled: false,
            eventCardTemplate: '',
            contactEmail: '',
            registerFormTemplate: '',
            isManualConfirmation: false,
            formSubmittedEmail: '',
            confirmationEmail: '',
            reserveListMessage: '',
        };
    }

    const [data, setData] = useState(!!(props.data) ? {...props.data} : createEmptyDataObject());
    const {open, setOpen, onSave} = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        onSave(data);
    };

    const handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setData(prev => ({
            ...prev,
            [key]: value
        }));
    }

    return (
        <Dialog open={open} maxWidth={'md'} onClose={handleClose}>
            <DialogTitle>
                Edytowanie wydarzenia
            </DialogTitle>
            <DialogContent>
                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                    labelText="Nazwa skrócona"
                                    id="shortName"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "shortName",
                                        value: data.shortName,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                                <CustomInput
                                    labelText="Nazwa pełna"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "name",
                                        value: data.name,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Data od"
                                    id="from"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "from",
                                        value: data.from,
                                        type: 'date',
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Data do"
                                    id="to"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "to",
                                        value: data.to,
                                        type: 'date',
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Miejsce"
                                    id="place"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "place",
                                        value: data.place,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Turnus"
                                    id="shift"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "shift",
                                        value: data.shift,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Liczba miejsc"
                                    id="maxParticipants"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "maxParticipants",
                                        type: "number",
                                        value: data.maxParticipants,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Kategoria wiekowa"
                                    id="ageCategory"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "ageCategory",
                                        value: data.ageCategory,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Zgłoszenia włączone"
                                    id="active"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "active",
                                        value: data.active,
                                        onChange: handleChange
                                    }}
                                    type="select"
                                    items={[{value: true, displayText: 'Tak'},
                                        {value: false, displayText: 'Nie'}]}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Generowanie karty zgłoszeniowej"
                                    id="cardGenerationEnabled"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "cardGenerationEnabled",
                                        value: data.cardGenerationEnabled,
                                        onChange: handleChange
                                    }}
                                    type="select"
                                    items={[{value: true, displayText: 'Tak'},
                                        {value: false, displayText: 'Nie'}]}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                                {!!(data.cardGenerationEnabled) ?
                                    <CustomInput
                                        labelText="Szablon karty zgłoszeniowej"
                                        id="eventCardTemplate"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: "eventCardTemplate",
                                            value: data.eventCardTemplate,
                                            onChange: handleChange
                                        }}
                                    /> : ''}

                            </GridItem>

                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Email kontaktowy"
                                    id="contactEmail"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "contactEmail",
                                        value: data.contactEmail,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Szablon forumalrza"
                                    id="registerFormTemplate"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "registerFormTemplate",
                                        value: data.registerFormTemplate,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Rodzaj potwierdzania zgłoszenia"
                                    id="isManualConfirmation"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: "isManualConfirmation",
                                        value: data.isManualConfirmation,
                                        onChange: handleChange
                                    }}
                                    type="select"
                                    items={[{value: true, displayText: 'Ręczne'},
                                        {value: false, displayText: 'Automatyczne(do wyczerpania limitu miejsc)'}]}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <InputLabel style={{color: '#AAAAAA'}}>Treść automatycznych wiadomości</InputLabel>
                                <CustomInput
                                    labelText="Wiadomość po przysłaniu zgłoszenie"
                                    id="formSubmittedEmail"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 6,
                                        name: "formSubmittedEmail",
                                        value: data.formSubmittedEmail,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="Wiadomość po potwierdzeniu zgłoszenia"
                                    id="confirmationEmail"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 6,
                                        name: "confirmationEmail",
                                        value: data.confirmationEmail,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="Wiadomość potwierdzająca wpisanie na listę rezerwową"
                                    id="reserveListMessage"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        multiline: true,
                                        rows: 6,
                                        name: "reserveListMessage",
                                        value: data.reserveListMessage,
                                        onChange: handleChange
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Anuluj
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>

    );
}