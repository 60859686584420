import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';
import { MenuItem, Select } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import MultiSelect from "../MultiSelect/MultiSelect";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
    const classes = useStyles();
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        success,
        type,
        control,
        data,
        setValue
    } = props;

    const labelClasses = classNames({
        [' ' + classes.labelRootError]: error,
        [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined,
    });

    const renderInput = () => {
        switch (type) {
            case 'select':
                return (
                    <Controller
                        render={(props) => ( <Select
                                id={id}
                                {...props}
                                MenuProps={{
                                    getContentAnchorEl: () => null,
                                }}
                                onChange={value => props.onChange(value)}
                            >
                                {data.map((item) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )
                           
                        }
                        name={id}
                        control={control}
                    />
                );
            case 'multiselect':
                return ( <MultiSelect
                        formControlProps={formControlProps}
                        labelText={labelText}
                        id={id}
                        labelProps={labelProps}
                        error={error}
                        success={success}
                        type={type}
                        control={control}
                        data={data}
                        setValue={setValue}
                    />
                );
            default:
                return (
                    <Input
                        classes={{
                            root: marginTop,
                            disabled: classes.disabled,
                            underline: underlineClasses,
                        }}
                        id={id}
                        {...inputProps}
                    />
                );
        }
    };

    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + ' ' + classes.formControl}
            error={error}
        >
            {labelText !== undefined && type !== 'multiselect' ? (
                labelText.length < 50 ? (
                    <InputLabel
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </InputLabel>
                ) : (
                    <label
                        className={classes.labelRoot + labelClasses}
                        htmlFor={id}
                        {...labelProps}
                    >
                        {labelText}
                    </label>
                )
            ) : null}
            {renderInput()}

            {error ? (
                <Clear
                    className={classes.feedback + ' ' + classes.labelRootError}
                />
            ) : success ? (
                <Check
                    className={
                        classes.feedback + ' ' + classes.labelRootSuccess
                    }
                />
            ) : null}
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
};
