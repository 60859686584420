/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from 'views/Login/Login';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import Register from 'views/Register/Register';
import { Provider } from 'react-redux';
import { store } from './helpers';
import { history } from './helpers';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
// core components
import Admin from 'layouts/Admin.js';

import 'assets/css/material-dashboard-react.css?v=1.9.0';
import Review from "./views/Register/Review";

ReactDOM.render(
  <Provider store={store}>
    <ReactNotification />
    <Router history={history}>
      <Switch>
        <PrivateRoute path="/admin" component={Admin} />
        <Route path="/login" component={Login} />
        <Route path="/opinia/:reviewerKey" component={Review} />
        <Route path="/rekolekcje/:eventId" component={Register} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
